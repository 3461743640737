@tailwind base;
@tailwind components;
@tailwind utilities;

.colorful-border {
  /* padding: 5px 8px; */
  cursor: pointer;
  position: relative;
}
.colorful-border::before {
  /* 1 */
  display: block;
  content: '';
  border-radius: inherit;
  border: var(--border-width, 2px) solid transparent;
  background: linear-gradient(45deg, #986eed, #e16a5e, #fcbf59, #ffe107) border-box; /* 2 */
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0); /* 3 */
  -webkit-mask-composite: xor; /* 4 */
  mask-composite: exclude;
  position: absolute;
  width: 100%;
  height: 100%;
}

.flex-col-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex-row-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}